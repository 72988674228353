import * as React from 'react';

const Footer = () => {
    return (
        <footer class="footer has-background-black">
            <div class="content has-text-centered has-text-white">
                <p>
                    Copyright @ are-you.gay 2023
                </p>
            </div>
        </footer>
    );
};

export default Footer;
